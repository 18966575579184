<template>
  <div class="pages-home-blog-list-wrap" ref="imageContainer">
    <!-- Heading part -->
    <header
      v-if="props.title || props.preTitle"
      class="m-bottom-l-1 pages-home-blog-list__header"
    >
      <p v-if="props.preTitle" class="text-l-1" v-anim-reveal-text>
        {{ props.preTitle }}
      </p>
      <div class="title-xl-1" v-html="props.title" v-anim-reveal-text />
    </header>

    <div>
      <ul class="pages-home-blog-list__list">
        <li v-for="item in items" :key="item.id">
          <PagesBlogListingBlock :item="item" :showTags="false" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagesHomeBlogList',
}
</script>

<script setup>
import { gsap, Power1 } from 'gsap'

const props = defineProps({
  items: Array,
  title: String,
  preTitle: String,
})

const actualSrc = ref(null)
const imageContainer = ref(null)
const floatingImg = ref(null)
</script>

<style lang="scss">
@import './style.scss';
</style>
