<template>
  <div class="technology-list-wrap" ref="imageContainer">
    <!-- Heading part -->
    <header v-if="props.title || props.preTitle" class="m-bottom-l-1">
      <p v-if="props.preTitle" class="text-l-1" v-anim-reveal-text>
        {{ props.preTitle }}
      </p>
      <div class="title-xl-1" v-html="props.title" v-anim-reveal-text />
    </header>

    <!-- Floating Image -->
    <div ref="floatingImg" class="imgX">
      <img :src="actualSrc" alt="Hover Image" />
    </div>

    <!-- List Buttons -->
    <ul class="technology-list flex align-center is-justify-center">
      <li
        v-for="technology in props.technologies"
        :key="technology.id"
        @mouseenter="handleMouseEnter(technology)"
        @mouseleave="handleMouseLeave"
      >
        <nuxt-link
          class="button-round-1 is-transparent-blue is-big"
          :to="
            localePath({
              name: 'technology-slug',
              params: { slug: technology.slug },
            })
          "
        >
          <span>{{ technology.title }}</span>
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PagesHomeTechnologyList',
}
</script>

<script setup>
import { gsap, Power1 } from 'gsap'

const props = defineProps({
  technologies: Array,
  title: String,
  preTitle: String,
})

const actualSrc = ref(null)
const imageContainer = ref(null)
const floatingImg = ref(null)

// get document coordinates of the element
function getCoords(elem) {
  const box = elem.getBoundingClientRect()
  return {
    top: box.top,
    right: box.right,
    bottom: box.bottom,
    left: box.left,
  }
}

function positionImage(e) {
  const coords = getCoords(imageContainer.value)
  const relX = e.clientX - coords.left
  const relY = e.clientY - coords.top
  // console.log(relX, relY)

  gsap.to(floatingImg.value, 0.2, {
    x: relX,
    y: relY,
    ease: Power1.easeOut,
    delay: 0.1,
  })
}

function handleMouseEnter(actualTech) {
  floatingImg.value.classList.add('--hovering')
  gsap.to(floatingImg.value, 0.3, { scale: 1, opacity: 1 })
  actualSrc.value = actualTech.imagePreviewListing.url
}

function handleMouseLeave() {
  floatingImg.value.classList.remove('--hovering')
  gsap.to(floatingImg.value, 0.3, { scale: 0.7, opacity: 0 })
}

function initFloatingImage() {
  const containerElem = imageContainer.value
  const img = floatingImg.value

  containerElem.addEventListener('pointerleave', function (e) {
    gsap.killTweensOf(img)
    gsap.to(img, 0.3, { scale: 0.7, opacity: 0 })
  })
  containerElem.addEventListener('pointermove', function (e) {
    positionImage(e)
  })
}

onMounted(() => {
  // Settiamo di base come immagine precaricata quella del primo elemento della lista
  const firstTechnology = props.technologies.find(
    (technology) => technology.imagePreviewListing?.url
  )
  actualSrc.value = firstTechnology
    ? firstTechnology.imagePreviewListing?.url
    : null

  // Init del sistema di movimento immagine
  initFloatingImage()
})
</script>

<style lang="scss">
@import './style.scss';
</style>
